import chalk, { ChalkFunction } from 'chalk'

export const nuxt = chalk.greenBright('[NUXT]')
export const serverMiddleware = chalk.magentaBright('[SERVER MIDDLEWARE]')
export const sitemap = chalk.magentaBright('[SITEMAP]')
export const robots = chalk.magentaBright('[ROBOTS]')
export const middleware = chalk.blueBright('[MIDDLEWARE]')
export const proxy = chalk.yellowBright('[PROXY]')
export const socket = chalk.magentaBright('[SOCKET]')
export const started = chalk.blueBright('[STARTED]')
export const cache = (name : string, color : ChalkFunction) => chalk.cyanBright(`[CACHE] ${color(`[${name}]`)}`)
export const func = (name : string) => chalk.magentaBright(`[FUNCTION] ${chalk.redBright(`[${name}]`)}`)
export const command = chalk.cyanBright('[COMMAND]')
export const hit = chalk.blueBright('[HIT]')
export const deprecated = chalk.magentaBright('[DEPRECATED]')
export const notFound = chalk.yellowBright('[NOT FOUND]')
export const add = chalk.greenBright('[ADD]')
export const update = chalk.greenBright('[UPDATE]')
export const size = chalk.yellowBright('[SIZE]')
export const initHandler = chalk.cyanBright('[INIT]')
export const globalLabels = chalk.cyanBright('[GLOBAL LABELS]')
export const legalEntities = chalk.cyanBright('[LEGAL ENTITIES]')
export const locations = chalk.cyanBright('[LOCATIONS]')
export const locales = chalk.cyanBright('[LOCALES]')
export const redirects = chalk.cyanBright('[REDIRECTS]')
export const filter = chalk.bgRedBright.black('[URL FILTER]')
export const pageRefMapping = chalk.cyanBright('[PAGE REF MAPPING]')
export const validLanguages = chalk.cyanBright('[VALID LANGUAGES]')
export const remoteDataset = chalk.cyanBright('[REMOTE DATASET]')
export const maintenance = chalk.cyanBright('[MAINTENANCE]')
export const projectService = chalk.cyanBright('[PROJECT SERVICE]')
export const verbose = chalk.bgWhite('[VERBOSE]')
export const info = chalk.bgBlueBright('[INFO]')
export const warn = chalk.bgYellowBright('[WARN]')
export const error = chalk.bgRedBright('[ERROR]')
export const performance = chalk.blackBright('[PERFORMANCE]')
